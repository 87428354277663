import(/* webpackMode: "eager" */ "/app/apps/web/src/app/[locale]/(components)/AdminLanguageMatcher.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/app/[locale]/(components)/DynamicTitleSetter.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/app/[locale]/(components)/ExternalServices.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/app/[locale]/(components)/Hotjar.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/app/[locale]/(components)/MaintenanceMode.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/app/[locale]/(components)/NextAuthSessionClientProvider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/app/[locale]/(components)/NotAuthenticated.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/app/[locale]/(components)/SignInButton.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/app/[locale]/(components)/StoreCleaner.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Accordion.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Alert.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Box.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Button.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Calendar.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Card.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Checkbox.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/ClientOnly.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/ConfirmationModal.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/EmptyMessage.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/FieldErrorMessage.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/FullscreenSpinner.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Icon.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Input.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Link.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/LottieAnimation.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Menu.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/MenuButton.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/NavigationLink.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Popover.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Select.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/SelectField.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Skeleton.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Spinner.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Table.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Tabs.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Tag.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Text.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Textarea.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/TextField.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Thumbnail.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Title.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Toast.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Toggle.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/Tooltip.tsx");
import(/* webpackMode: "eager" */ "/app/apps/web/src/providers/TRPCProvider.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-intl@3.4.3_next@14.1.0_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"../../fonts/Geist-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../fonts/Geist-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../fonts/Geist-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"geist\"}")